import { AfterViewChecked, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})

export class HeaderComponent implements OnInit, AfterViewChecked {
  public selectedLanguage: string;
  public storeLanguage: string;
  public availableLanguages: string[] = ['pt', 'en'];
  public urlPortugal: string;

  @Output() onSelectedLanguage = new EventEmitter<string>();

  constructor(private translate: TranslateService, private cookieService: CookieService) {
    this.storeLanguage = localStorage.getItem('selectedLanguage');
    this.selectedLanguage = this.storeLanguage ? this.storeLanguage.slice(0, 2) : 'pt_PT';
    
    this.urlPortugal = environment.urlPortugal;

  }

  ngOnInit() {
  }



  ngAfterViewChecked() {
    const languageCookie = this.cookieService.get("GUEST_LANGUAGE_ID");

    if (languageCookie != this.storeLanguage){
      this.languageChanged(languageCookie)
      window.location.reload();
    }
  }

  public languageChanged(language: any): void {
    this.translate.use(language.slice(0, 2));
    localStorage.setItem('selectedLanguage', language);
  }

  changeElementsLanguageSelected(language: string): void {

    var elementHtml = document.getElementById("rootNode");
    elementHtml.setAttribute("lang", this.storeLanguage);

    var elements = document.getElementsByName("dllLanguageDesktop");
    elements.forEach(element => {

      var dataActive = element.getAttribute("data-active");

      var texto = element.innerText;
      if (texto.toUpperCase().trim() === language.toUpperCase()){
        
        if (!dataActive){
          element.setAttribute("data-active", "true");
        }
      }
      else {
        if (dataActive) {
          element.removeAttribute("data-active");
        }
      }
    });
  }
}

export interface Item {
  value: string;
  viewValue: string;
}

