<!-- main content -->

<app-header ></app-header>
<div class="ept"><!--Div faz com que mapa fique no centro da pagina-->
<main id="eptMain" class="ept-main ept-mt--s">
    
    <app-index></app-index>
    
</main>
        <!-- WIDGET DE ASSISTÊNCIA (SUPORTE, FEEDBACK E CHATBOT) -->
        <!--  Widget container  -->
        <div class="wa" id="widgetAssist">
            <!--  Suporte & Feedback container  -->
            <div class="wa__top" hidden="true">
                <ul class="wa__rootmenu d-flex flex-row flex-lg-column align-items-lg-end">
                    <!--  Suporte  -->
                    <li class="wa__rootmenu__item order-lg-0 d-lg-flex flex-lg-row mb-lg-2">
                        <button id="waBtnSupport"
                            class="ept-btn ept-btn--wicon-r wa__btn wa__btn--v d-lg-flex flex-lg-column align-items-lg-center"
                            aria-haspopup="true" title="Suporte">
                            <span class="order-lg-1">Suporte</span>
                            <svg class="icon30" aria-hidden="true" focusable="false">
                                <use href="#suporte"></use>
                            </svg>
                        </button>
                        <!-- menu de suporte -->
                        <ul class="wa__supmenu wa--hidden" id="waSuppMenu">

                        </ul>
                        <!-- /menu de suporte -->
                    </li>
                    <!--  /Suporte  -->
                </ul>
            </div>
            <!--  /Suporte & Feedback container  -->
            <!--  Chatbot  -->
            <div class="wa__bottom" id="waBtnChatbot">
                <button class="wa__btn wa__btn--chatbot d-flex justify-content-center align-items-center tooltip-button"
                    data-toggle="tooltip" data-placement="top" title="Falar com assistente digital" data-rel="chatbot"
                    data-action="to_open" aria-label="Falar com assistente digital" id="waBtnChatbotBtn">
                    <svg class="icon30" aria-hidden="true" focusable="false">
                        <use href="#boticon"></use>
                    </svg>
                </button>
            </div>
            <!--  /Chatbot  -->
            <!--  MODALS  -->
            <!--  click to call  -->
            <div class="wa__win wa--hidden" role="dialog" aria-modal="true" data-win="call" data-status="form">
                <div class="wa__win__header">
                    <p class="wa__header">
                        Contacto telefónico
                    </p>
                    <div class="wa__win__header__btns">
                        <a href="https://www2.digital.gov.pt" data-rel="call" data-action="to_minimize"
                            class="wa__btn--minimize" aria-label="Minimizar janela de contactos">
                            <svg class="">
                                <use href="#icon-minimize"></use>
                            </svg>
                        </a>
                        <a href="https://www2.digital.gov.pt" data-rel="call" data-action="to_close"
                            class="wa__btn--close" aria-label="Fechar janela de contactos">
                            <svg class="">
                                <use href="#icon-close"></use>
                            </svg>
                        </a>
                        <a href="https://www2.digital.gov.pt" data-rel="call" data-action="to_maximize"
                            class="wa__btn--maximize" aria-label="Maximizar janela de contactos">
                            <svg class="">
                                <use href="#icon-arrow-up"></use>
                            </svg>
                        </a>
                    </div>
                </div>
                <div id="click2callContainer" class="wa__win__body">
                    <form id="contactForm" class="wa__form" data-rel="call">
                        <fieldset>
                            <!--  header  -->
                            <legend>
                                <span class="wa__title">Contacto telefónico</span>
                            </legend>
                            <!--  input name  -->
                            <button type="submit" class="d-none">
                                Enviar pedido
                                <svg class="icon36">
                                    <use href="#seta_direita" />
                                </svg>
                            </button>
                        </fieldset>
                        <!--  LISTA DE CONTACTOS  -->
                        <aside class="wa__win__contacts">
                            <p class="wa__desc">As linhas de apoio ao portal ePortugal estão disponíveis aos dias úteis,
                                das 9h às 18h. Pode entrar em contacto connosco através dos seguintes números de
                                telefone:</p>
                            <h5 class="wa__subtitle">Centro de contacto cidadão</h5>
                            <p class="wa__phone-nr"><a aria-label="300, 003, 990" href="tel:300003990">300 003 990</a>
                            </p>
                            <p class="wa__phone-nr"><a aria-label="210, 489, 010" href="tel:210489010">210 489 010</a>
                            </p>
                            <h5 class="wa__subtitle">Centro de contacto empresas</h5>
                            <p class="wa__phone-nr"><a aria-label="300, 003, 980" href="tel:300003980">300 003 980</a>
                            </p>
                            <p class="wa__phone-nr"><a aria-label="210, 489, 011" href="tel:210489011">210 489 011</a>
                            </p>
                        </aside>
                        <!--  /LISTA DE CONTACTOS  -->
                    </form>
                </div>
            </div>
            <!--  videocall  -->
            <div class="wa__win wa--hidden" role="dialog" aria-modal="true" data-win="videocall" data-status="form">
                <input type="hidden" style="display: none" id="cesCodeVideo_var" value="" />
                <div class="wa__win__header">
                    <p class="wa__header">
                        Agendar videochamada
                    </p>
                    <div class="wa__win__header__btns">
                        <a href="https://www2.digital.gov.pt" data-rel="videocall" data-action="to_minimize"
                            class="wa__btn--minimize" aria-label="Minimizar janela de contactos">
                            <svg class="">
                                <use href="#icon-minimize"></use>
                            </svg>
                        </a>
                        <a href="https://www2.digital.gov.pt" data-rel="videocall" data-action="to_close"
                            class="wa__btn--close" aria-label="Fechar janela de contactos">
                            <svg class="">
                                <use href="#icon-close"></use>
                            </svg>
                        </a>
                        <a href="https://www2.digital.gov.pt" data-rel="videocall" data-action="to_maximize"
                            class="wa__btn--maximize" aria-label="Maximizar janela de contactos">
                            <svg class="">
                                <use href="#icon-arrow-up"></use>
                            </svg>
                        </a>
                    </div>
                </div>
                <div id="wa__videoContainer" class="wa__win__body">
                    <form id="contactForm--videocall" class="wa__form" data-rel="videocall">
                        <fieldset>
                            <!--  header  -->
                            <legend class="d-flex flex-column">
                                <span class="wa__title">Videochamada</span>
                                <span class="wa__desc">Preencha o seguinte formulário para agendar a
                                    videochamada.</span>
                                <span class="wa__info">Todos os campos são obrigatórios.</span>
                            </legend>
                            <!--  input name  -->
                            <div class="wa__form__field">
                                <label class="label--block" for="waVideoName">Nome</label>
                                <input aria-required="true" type="text" class="wa__input wa__input__name"
                                    id="waVideoName" placeholder="Indique o seu nome" value="" />
                                <span class="error-message">O campo "Nome" é obrigatório.</span>
                            </div>
                            <!--  input email  -->
                            <div class="wa__form__field">
                                <label class="label--block" for="waVideoEmail">E-mail</label>
                                <input aria-required="true" type="text" class="wa__input wa__input__email"
                                    id="waVideoEmail" placeholder="Indique o seu endereço de e-mail" value="" />
                                <span class="error-message">Por favor introduza email válido.</span>
                            </div>
                            <!--  input phone  -->
                            <div class="wa__form__field">
                                <label class="label--block" for="waCallPhone">Telefone</label>
                                <input aria-required="true" name="phone" type="tel" class="wa__input wa__input__phone"
                                    id="waVideoPhone" placeholder="Indique o seu nº de telefone' />" value="" />
                                <span class="error-message">Este campo é obrigatório.</span>
                            </div>
                            <!--  input subject  -->
                            <div class="wa__form__field">
                                <label class="label--block" for="waVideoSubject">Assunto</label>
                                <input disabled aria-required="true" type="text" class="wa__input wa__input__subject"
                                    id="waVideoSubject" placeholder="Indique o assunto do pedido de contacto"
                                    value="" />
                                <span class="error-message">O campo "Assunto" é obrigatório.</span>
                            </div>
                            <!--  input date  -->
                            <div class="wa__form__field">
                                <div class="wa__input__cal__container" id="wa__datepicker--video">
                                    <span class="wa__input__cal--label">Escolher dia</span>
                                    <input disabled type="hidden" id="msghour" value="Escolher hora -- : --" />
                                    <div class="wa__input__cal--text">
                                        <label class="hide-accessible" for="wa__input__cal--day">Dia</label>
                                        <input class="wa__input wa__input__cal wa__input__cal--day" type="text"
                                            placeholder="--" name="wa__input__cal--day" aria-required="true">
                                        <span>/</span>
                                        <label class="hide-accessible" for="wa__input__cal--month">Mes</label>
                                        <input class="wa__input wa__input__cal wa__input__cal--month" type="text"
                                            placeholder="--" name="wa__input__cal--month" aria-required="true">
                                        <span>/</span>
                                        <label class="hide-accessible" for="wa__input__cal--year">Ano</label>
                                        <input class="wa__input wa__input__cal wa__input__cal--year" type="text"
                                            placeholder="----" name="wa__input__cal--year" aria-required="true">
                                    </div>
                                </div>
                                <span class="error-message">Este campo é obrigatório.</span>
                            </div>
                            <!--  input time  -->
                            <div id="div-wa-input-time" class="wa__form__field wa--hidden">
                                <div class="wa__select__container">
                                    <label class="hide-accessible" for="wa-select-input-time">Horário</label>
                                    <select id="wa-select-input-time"
                                        class="wa__input wa__input--select wa__input__time" name="wa__input__time"
                                        aria-required="true">
                                    </select>
                                </div>
                                <span class="error-message">Este campo é obrigatório.</span>
                            </div>
                            <!--  captcha  -->
                            <input type="hidden" name="captchatoken" id="captchatoken--videocall" value="" />

                            <!--  submit button  -->
                            <div class="checkbox-card filter-level">
                                <div class="form-group form-inline input-checkbox-wrapper inp-check">
                                    <label for="checkbox-name-video-terms">
                                        <input class="treatAsInput field non-required need_tabIndex"
                                            name="checkbox-name-video-terms" value="" type="checkbox" tabindex="0"
                                            onclick="acceptTermsVideo()">
                                        Li e aceito os <a rel="noopener"
                                            href="https://www2.digital.gov.pt/termos-e-condicoes/termos-e-condicoes-do-atendimento-por-videochamada"
                                            target="_blank">termos e condições do atendimento por videochamada.</a>
                                    </label>
                                </div>
                            </div>

                            <button type="submit" class="ept-btn--sec ept-btn--wicon-r noClick"
                                id="sendContactVideocall">Enviar pedido
                                <svg class="icon36">
                                    <use href="#seta_direita" />
                                </svg>
                            </button>

                        </fieldset>
                    </form>
                    <div class="wa__success__screen wa--hidden">
                        <svg class="nc-icon icon-success">
                            <use href="#icon-checked_circle" />
                        </svg>
                        <p class="wa__title">Pedido de agendamento submetido com sucesso.</p>
                        <p>Tem 15 minutos para aceder ao seu e-mail e concluir o agendamento.</p>
                        <div class="wa__msg__close__container">
                            <button class="btn fechar-msg button wa__btn--close" type="button" data-action="to_close"
                                data-rel="videocall" data-msg="true">
                                Fechar
                                <svg class="nc-icon">
                                    <use href="#icon-close" />
                                </svg>
                            </button>
                        </div>
                    </div>
                    <div class="wa__error__screen wa--hidden">
                        <svg class="nc-icon icon-error">
                            <use href="#icon-close" />
                        </svg>
                        <p class="wa__title">Ocorreu um erro</p>
                        <p>Por favor, tente mais tarde</p>
                        <div class="wa__msg__close__container">
                            <p class="captcha__msg wa--hidden">Se acabou de fazer um pedido de contacto, terá de esperar
                                cerca de dois minutos até que seja possível enviar novo pedido</p>
                            <button class="btn fechar-msg button wa__btn--close" type="button" data-action="to_close"
                                data-rel="videocall" data-msg="true">Fechar
                                <svg class="nc-icon">
                                    <use href="#icon-close" />
                                </svg>
                            </button>
                        </div>
                    </div>
                    <!--  Processing screen  -->
                    <section class="wa__processing wa--hidden">
                        <header>
                            <h2 class="wa__subtitle">Estamos a processar o seu pedido...</h2>
                        </header>
                        <p>Por favor, aguarde.</p>
                    </section>
                </div>
            </div>
            <script>
                var btnSubmitForm = document.getElementById('sendContactVideocall');
                function acceptTermsVideo() {
                    if (btnSubmitForm) {
                        if (btnSubmitForm.classList.contains('noClick')) {
                            btnSubmitForm.classList.remove('noClick');
                        }
                        else {
                            btnSubmitForm.classList.add('noClick');
                        }
                    }
                }
            </script>
            <!--  feedback  -->
            <div class="wa__win wa--hidden" role="dialog" aria-modal="true" data-win="feedback" data-status="form">
                <div class="wa__win__header">
                    <p class="wa__header">
                        Dê-nos a sua opinião sobre esta página
                    </p>
                    <div class="wa__win__header__btns">
                        <a href="https://www2.digital.gov.pt" data-rel="feedback" data-action="to_minimize"
                            class="wa__btn--minimize" aria-label="Minimizar janela de contactos">
                            <svg class="">
                                <use href="#icon-minimize"></use>
                            </svg>
                        </a>
                        <a href="https://www2.digital.gov.pt" data-rel="feedback" data-action="to_close"
                            class="wa__btn--close" aria-label="Fechar janela de contactos">
                            <svg class="">
                                <use href="#icon-close"></use>
                            </svg>
                        </a>
                        <a href="https://www2.digital.gov.pt" data-rel="feedback" data-action="to_maximize"
                            class="wa__btn--maximize" aria-label="Maximizar janela de contactos">
                            <svg class="">
                                <use href="#icon-arrow-up"></use>
                            </svg>
                        </a>
                    </div>
                </div>
                <div id="wa__feedbackContainer" class="wa__win__body">
                    <form id="contactForm--feedback" class="wa__form" data-rel="feedback">
                        <fieldset>
                            <!--  header  -->
                            <legend class="d-flex flex-column">
                                <span class="wa__title">Envie-nos o seu feedback</span>
                                <span class="wa__desc">Dê-nos a sua opinião sobre os conteúdos desta página. Pode
                                    deixar-nos um comentário sobre o que podemos melhorar. Não vai receber resposta ao
                                    seu comentário.<br><br>Use o <a href="/contactos#formulario">formulário de
                                        contacto</a> para esclarecer dúvidas sobre a informação e os serviços
                                    disponibilizados neste portal.</span>
                                <span class="wa__info">* campo obrigatório</span>
                            </legend>
                            <!--  found what you were looking for?  -->
                            <div class="wa__form__field">
                                <p>Encontrou o que procurava?*</p>
                                <div class="wa__feedback__looking4">
                                    <!--  yes  -->
                                    <div class="wa__feedback__looking4__field">
                                        <input type="radio" name="wa__feedback__looking4"
                                            id="wa__feedback__looking4--yes" value="yes" aria-required="true" />
                                        <label for="wa__feedback__looking4--yes">Sim</label>
                                    </div>
                                    <!--  no  -->
                                    <div class="wa__feedback__looking4__field">
                                        <input type="radio" name="wa__feedback__looking4"
                                            id="wa__feedback__looking4--no" value="no" aria-required="true" />
                                        <label for="wa__feedback__looking4--no">Não</label>
                                    </div>
                                    <!--  partially  -->
                                    <div class="wa__feedback__looking4__field">
                                        <input type="radio" name="wa__feedback__looking4"
                                            id="wa__feedback__looking4--part" value="partially" aria-required="true" />
                                        <label for="wa__feedback__looking4--part">Parcialmente</label>
                                    </div>
                                </div>
                                <!--  error msg  -->
                                <span class="error-message">Este campo é obrigatório.</span>
                            </div>
                            <!--  rate your experience  -->
                            <div class="wa__form__field">
                                <p class="label--block">Classifique a sua experiência*</p>
                                <!--  labels  -->
                                <!--  1  -->
                                <label class="hide-accessible" for="wa__feedback__rate--lowest">Muito má</label>
                                <!--  2  -->
                                <label class="hide-accessible" for="wa__feedback__rate--low">Má</label>
                                <!--  3  -->
                                <label class="hide-accessible" for="wa__feedback__rate--ok">Razoável</label>
                                <!--  4  -->
                                <label class="hide-accessible" for="wa__feedback__rate--high">Boa</label>
                                <!--  5  -->
                                <label class="hide-accessible" for="wa__feedback__rate--highest">Muito boa</label>
                                <div class="wa__feedback__rate__wrapper">
                                    <span class="wa__info__sm">Muito má</span>
                                    <!--  ratings container  -->
                                    <div id="waFeedbackRateContainer">
                                        <!--  1  -->
                                        <input type="radio" name="wa__feedback__rate" id="wa__feedback__rate--lowest"
                                            value="1" aria-required="true" />
                                        <!--  2  -->
                                        <input type="radio" name="wa__feedback__rate" id="wa__feedback__rate--low"
                                            value="2" aria-required="true" />
                                        <!--  3  -->
                                        <input type="radio" name="wa__feedback__rate" id="wa__feedback__rate--ok"
                                            value="3" aria-required="true" />
                                        <!--  4  -->
                                        <input type="radio" name="wa__feedback__rate" id="wa__feedback__rate--high"
                                            value="4" aria-required="true" />
                                        <!--  5  -->
                                        <input type="radio" name="wa__feedback__rate" id="wa__feedback__rate--highest"
                                            value="5" aria-required="true" />
                                    </div>
                                    <!--  very bad text  -->
                                    <span class="wa__info__sm">Muito boa</span>
                                </div>
                                <!--  error msg  -->
                                <span class="error-message">Este campo é obrigatório.</span>
                            </div>
                            <!--  remarks  -->
                            <div class="wa__form__field">
                                <label class="label--block" for="waFeedbackRemarks">Comentário</label>
                                <textarea class="wa__input wa__input--textarea wa__input__remarks"
                                    placeholder="Em que podemos melhorar?" name="wa-feedback-remarks"
                                    id="waFeedbackRemarks" maxlength="500"></textarea>
                            </div>
                            <!--  captcha  -->
                            <input type="hidden" name="captchatoken" id="captchatoken--feedback" value="" />
                            <!--  submit button  -->
                            <button type="submit" class="ept-btn--sec ept-btn--wicon-r" id="sendContactFeedback">Enviar
                                feedback
                                <svg class="icon36">
                                    <use href="#seta_direita" />
                                </svg>
                            </button>
                        </fieldset>
                    </form>
                    <!--  feedback survey  -->
                    <form id="contactForm--survey" class="wa__form wa--hidden" data-rel="survey">
                        <fieldset>
                            <!--  header  -->
                            <legend class="d-flex flex-column">
                                <span class="wa__title">Questionário</span>
                                <span class="wa__desc">Por favor, diga-nos mais sobre a informação que encontrou.</span>
                            </legend>
                            <!--  rate your experience (accurate)  -->
                            <div class="wa__form__field">
                                <p class="label--block">A informação está correta?</p>
                                <div class="wa__feedback__rate__wrapper">
                                    <!--  ratings container  -->
                                    <div id="waFBRatingsAcc" class="wa__feedback__rate__inputs">
                                        <!--  1  -->
                                        <input type="radio" name="waFBRateAcc" id="waFBRateAcc--1" value="1"
                                            aria-required="true" />
                                        <!--  2  -->
                                        <input type="radio" name="waFBRateAcc" id="waFBRateAcc--2" value="2"
                                            aria-required="true" />
                                        <!--  3  -->
                                        <input type="radio" name="waFBRateAcc" id="waFBRateAcc--3" value="3"
                                            aria-required="true" />
                                        <!--  4  -->
                                        <input type="radio" name="waFBRateAcc" id="waFBRateAcc--4" value="4"
                                            aria-required="true" />
                                        <!--  5  -->
                                        <input type="radio" name="waFBRateAcc" id="waFBRateAcc--5" value="5"
                                            aria-required="true" />
                                    </div>
                                    <!--  labels  -->
                                    <div class="wa__feedback__rate__labels">
                                        <!--  1  -->
                                        <label for="waFBRateAcc--1">1</label>
                                        <!--  2  -->
                                        <label for="waFBRateAcc--2">2</label>
                                        <!--  3  -->
                                        <label for="waFBRateAcc--3">3</label>
                                        <!--  4  -->
                                        <label for="waFBRateAcc--4">4</label>
                                        <!--  5  -->
                                        <label for="waFBRateAcc--5">5</label>
                                    </div>
                                </div>
                                <!--  error msg  -->
                                <span class="error-message">Este campo é obrigatório.</span>
                            </div>
                            <!--  rate your experience (comprehensive)  -->
                            <div class="wa__form__field">
                                <p class="label--block">A informação é abrangente o suficiente?</p>
                                <div class="wa__feedback__rate__wrapper">
                                    <!--  ratings container  -->
                                    <div id="waFBRatingsComp" class="wa__feedback__rate__inputs">
                                        <!--  1  -->
                                        <input type="radio" name="waFBRateComp" id="waFBRateComp--1" value="1"
                                            aria-required="true" />
                                        <!--  2  -->
                                        <input type="radio" name="waFBRateComp" id="waFBRateComp--2" value="2"
                                            aria-required="true" />
                                        <!--  3  -->
                                        <input type="radio" name="waFBRateComp" id="waFBRateComp--3" value="3"
                                            aria-required="true" />
                                        <!--  4  -->
                                        <input type="radio" name="waFBRateComp" id="waFBRateComp--4" value="4"
                                            aria-required="true" />
                                        <!--  5  -->
                                        <input type="radio" name="waFBRateComp" id="waFBRateComp--5" value="5"
                                            aria-required="true" />
                                    </div>
                                    <!--  labels  -->
                                    <div class="wa__feedback__rate__labels">
                                        <!--  1  -->
                                        <label for="waFBRateComp--1">1</label>
                                        <!--  2  -->
                                        <label for="waFBRateComp--2">2</label>
                                        <!--  3  -->
                                        <label for="waFBRateComp--3">3</label>
                                        <!--  4  -->
                                        <label for="waFBRateComp--4">4</label>
                                        <!--  5  -->
                                        <label for="waFBRateComp--5">5</label>
                                    </div>
                                </div>
                                <!--  error msg  -->
                                <span class="error-message">Este campo é obrigatório.</span>
                            </div>
                            <!--  rate your experience (clear)  -->
                            <div class="wa__form__field">
                                <p class="label--block">A linguagem é fácil de perceber?</p>
                                <div class="wa__feedback__rate__wrapper">
                                    <!--  ratings container  -->
                                    <div id="waFBRatingsClear" class="wa__feedback__rate__inputs">
                                        <!--  1  -->
                                        <input type="radio" name="waFBRateClear" id="waFBRateClear--1" value="1"
                                            aria-required="true" />
                                        <!--  2  -->
                                        <input type="radio" name="waFBRateClear" id="waFBRateClear--2" value="2"
                                            aria-required="true" />
                                        <!--  3  -->
                                        <input type="radio" name="waFBRateClear" id="waFBRateClear--3" value="3"
                                            aria-required="true" />
                                        <!--  4  -->
                                        <input type="radio" name="waFBRateClear" id="waFBRateClear--4" value="4"
                                            aria-required="true" />
                                        <!--  5  -->
                                        <input type="radio" name="waFBRateClear" id="waFBRateClear--5" value="5"
                                            aria-required="true" />
                                    </div>
                                    <!--  labels  -->
                                    <div class="wa__feedback__rate__labels">
                                        <!--  1  -->
                                        <label for="waFBRateClear--1">1</label>
                                        <!--  2  -->
                                        <label for="waFBRateClear--2">2</label>
                                        <!--  3  -->
                                        <label for="waFBRateClear--3">3</label>
                                        <!--  4  -->
                                        <label for="waFBRateClear--4">4</label>
                                        <!--  5  -->
                                        <label for="waFBRateClear--5">5</label>
                                    </div>
                                </div>
                                <!--  error msg  -->
                                <span class="error-message">Este campo é obrigatório.</span>
                            </div>
                            <!--  multiple choice (update date)  -->
                            <div class="wa__form__field">
                                <p>A data da última atualização da informação está disponível na página?</p>
                                <div class="wa__feedback__looking4">
                                    <!--  yes  -->
                                    <div class="wa__feedback__looking4__field">
                                        <input type="radio" name="waFBMultChoiceUpdate" id="waFBMultChoiceUpdate--yes"
                                            value="yes" aria-required="true" />
                                        <label for="waFBMultChoiceUpdate--yes">Sim</label>
                                    </div>
                                    <!--  no  -->
                                    <div class="wa__feedback__looking4__field">
                                        <input type="radio" name="waFBMultChoiceUpdate" id="waFBMultChoiceUpdate--no"
                                            value="no" aria-required="true" />
                                        <label for="waFBMultChoiceUpdate--no">Não</label>
                                    </div>
                                    <!--  don't know  -->
                                    <div class="wa__feedback__looking4__field">
                                        <input type="radio" name="waFBMultChoiceUpdate" id="waFBMultChoiceUpdate--dk"
                                            value="dk" aria-required="true" />
                                        <label for="waFBMultChoiceUpdate--dk">Não sei</label>
                                    </div>
                                </div>
                                <!--  error msg  -->
                                <span class="error-message">Este campo é obrigatório.</span>
                            </div>
                            <!--  multiple choice (ownership)  -->
                            <div class="wa__form__field">
                                <p>O nome da autoridade responsável pela informação está disponível na página?</p>
                                <div class="wa__feedback__looking4">
                                    <!--  yes  -->
                                    <div class="wa__feedback__looking4__field">
                                        <input type="radio" name="waFBMultChoiceOwner" id="waFBMultChoiceOwner--yes"
                                            value="yes" aria-required="true" />
                                        <label for="waFBMultChoiceOwner--yes">Sim</label>
                                    </div>
                                    <!--  no  -->
                                    <div class="wa__feedback__looking4__field">
                                        <input type="radio" name="waFBMultChoiceOwner" id="waFBMultChoiceOwner--no"
                                            value="no" aria-required="true" />
                                        <label for="waFBMultChoiceOwner--no">Não</label>
                                    </div>
                                    <!--  don't know  -->
                                    <div class="wa__feedback__looking4__field">
                                        <input type="radio" name="waFBMultChoiceOwner" id="waFBMultChoiceOwner--dk"
                                            value="dk" aria-required="true" />
                                        <label for="waFBMultChoiceOwner--dk">Não sei</label>
                                    </div>
                                </div>
                                <!--  error msg  -->
                                <span class="error-message">Este campo é obrigatório.</span>
                            </div>
                            <!--  multiple choice (references)  -->
                            <div class="wa__form__field">
                                <p>Há referências a legislação?</p>
                                <div class="wa__feedback__looking4">
                                    <!--  yes  -->
                                    <div class="wa__feedback__looking4__field">
                                        <input type="radio" name="waFBMultChoiceRefs" id="waFBMultChoiceRefs--yes"
                                            value="yes" aria-required="true" />
                                        <label for="waFBMultChoiceRefs--yes">Sim</label>
                                    </div>
                                    <!--  no  -->
                                    <div class="wa__feedback__looking4__field">
                                        <input type="radio" name="waFBMultChoiceRefs" id="waFBMultChoiceRefs--no"
                                            value="no" aria-required="true" />
                                        <label for="waFBMultChoiceRefs--no">Não</label>
                                    </div>
                                    <!--  not relevant  -->
                                    <div class="wa__feedback__looking4__field">
                                        <input type="radio" name="waFBMultChoiceRefs" id="waFBMultChoiceRefs--dk"
                                            value="dk" aria-required="true" />
                                        <label for="waFBMultChoiceRefs--dk">Não é relevante</label>
                                    </div>
                                </div>
                                <!--  error msg  -->
                                <span class="error-message">Este campo é obrigatório.</span>
                            </div>
                            <!--  multiple choice (english)  -->
                            <div class="wa__form__field">
                                <p>A informação está disponível em inglês?</p>
                                <div class="wa__feedback__looking4">
                                    <!--  yes  -->
                                    <div class="wa__feedback__looking4__field">
                                        <input type="radio" name="waFBMultChoiceEN" id="waFBMultChoiceEN--yes"
                                            value="yes" aria-required="true" />
                                        <label for="waFBMultChoiceEN--yes">Sim</label>
                                    </div>
                                    <!--  no  -->
                                    <div class="wa__feedback__looking4__field">
                                        <input type="radio" name="waFBMultChoiceEN" id="waFBMultChoiceEN--no" value="no"
                                            aria-required="true" />
                                        <label for="waFBMultChoiceEN--no">Não</label>
                                    </div>
                                    <!--  don't know  -->
                                    <div class="wa__feedback__looking4__field">
                                        <input type="radio" name="waFBMultChoiceEN" id="waFBMultChoiceEN--dk" value="dk"
                                            aria-required="true" />
                                        <label for="waFBMultChoiceEN--dk">Não sei</label>
                                    </div>
                                </div>
                                <!--  error msg  -->
                                <span class="error-message">Este campo é obrigatório.</span>
                            </div>
                            <!--  captcha  -->
                            <input type="hidden" name="captchatoken" id="captchatoken--survey" value="" />
                            <!--  submit button  -->
                            <button type="submit" class="ept-btn--sec ept-btn--wicon-r" id="sendContactSurvey">
                                Submeter Questionário
                                <svg class="icon36">
                                    <use href="#seta_direita" />
                                </svg>
                            </button>
                        </fieldset>
                    </form>
                    <!--  Feedback success screen (after the 1st submission and before the survey)  -->
                    <section class="wa__fb__success wa--hidden" id="waFBSuccess">
                        <header>
                            <h2 class="wa__subtitle">Feedback submetido com sucesso.</h2>
                        </header>
                        <p>Pretende responder a um questionário sobre a sua experiência?</p>
                        <!--  more questions link  -->
                        <a class="wa__link" id="waFBGo2Survey"
                            title="<@liferay.language key='pt.portal.ama.widgetAssist.link.feedback.clickSurvey'/>">
                            Responder ao questionário
                        </a>
                    </section>
                    <!--  /Feedback success screen (after the 1st submission and before the survey)  -->
                    <div class="wa__success__screen wa--hidden">
                        <svg class="nc-icon icon-success">
                            <use href="#icon-checked_circle" />
                        </svg>
                        <p class="wa__title">Feedback submetido com sucesso.</p>
                        <p>O seu feedback foi submetido. Obrigado.</p>
                        <div class="wa__msg__close__container">
                            <button class="btn fechar-msg button wa__btn--close" type="button" data-action="to_close"
                                data-rel="feedback" data-msg="true">
                                Fechar
                                <svg class="nc-icon">
                                    <use href="#icon-close" />
                                </svg>
                            </button>
                        </div>
                    </div>
                    <div class="wa__error__screen wa--hidden">
                        <svg class="nc-icon icon-error">
                            <use href="#icon-close" />
                        </svg>
                        <p class="wa__title">Ocorreu um erro</p>
                        <p>Por favor, tente mais tarde</p>
                        <div class="wa__msg__close__container">
                            <p class="captcha__msg wa--hidden">Se acabou de fazer um pedido de contacto, terá de esperar
                                cerca de dois minutos até que seja possível enviar novo pedido</p>
                            <button class="btn fechar-msg button wa__btn--close" type="button" data-action="to_close"
                                data-rel="feedback" data-msg="true">Fechar
                                <svg class="nc-icon">
                                    <use href="#icon-close" />
                                </svg>
                            </button>
                        </div>
                    </div>
                    <!--  Processing screen  -->
                    <section class="wa__processing wa--hidden">
                        <header>
                            <h2 class="wa__subtitle">Estamos a processar o seu pedido...</h2>
                        </header>
                        <p>Por favor, aguarde.</p>
                    </section>
                </div>
            </div>
            <!--  chatbot  -->
            <div id="widgetAssistBot" class="wa__win wa--hidden" role="dialog" aria-modal="true" data-win="chatbot"
                data-status="form"></div>
            <!--  /MODALS  -->
        </div>
        <!--  /Widget container  -->
        <!--  REDIRECTING SCREEN (FROM THE HELP VIA EMAIL MENU)  -->
        <section class="wa__redirecting wa--hidden" id="waRedirectingScreen">
            <h4 class="wa__redirecting__header">Contacto por e-mail</h4>
            <p class="wa__redirecting__desc">Vai ser encaminhada/o para o formulário de contactos do ePortugal.</p>
            <span class="wa__redirecting__anim"></span>
        </section>
        <!--  /REDIRECTING SCREEN  -->
        <!-- /WIDGET DE ASSISTÊNCIA (SUPORTE, FEEDBACK E CHATBOT) -->

    
    </div>
<app-footer></app-footer>
